import React, { useState, useEffect, useRef } from "react";
import "./Home.css"; // Ensure you have the corresponding CSS file
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  setDoc,
  getDoc, // Import getDoc to fetch a single document

} from "firebase/firestore";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { SecularOneRegularBase64 } from "./fontSecularReg"; // Adjust the path as necessary

pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts.vfs;
pdfMake.vfs["DejaVuSans-normal.ttf"] = SecularOneRegularBase64;

pdfMake.fonts = {
  DejaVuSans: {
    normal: "DejaVuSans-normal.ttf",
    bold: "DejaVuSans-normal.ttf",
    italics: "DejaVuSans-normal.ttf",
    bolditalics: "DejaVuSans-normal.ttf",
  },
  // ... other fonts
};

function reverseHebrewText(text) {
  // Split the text into words, reverse them, and then join them with spaces before and after each word
  return text
    .split(" ")
    .reverse()
    .map((word) => " " + word + " ")
    .join("")
    .trim();
}

function reverseCustomerName(name) {
  return name.split(" ").reverse().join(" ");
}

function Home() {
  const [itemQuantities, setItemQuantities] = useState([]);
  const [items, setItems] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showGeneralPopup, setShowGeneralPopup] = useState(false);
  const [generalQuantity, setGeneralQuantity] = useState(0);
  const categoryOrder = { Flowers: 1, Vegetables: 2, Others: 3 };
  const [filterQuery, setFilterQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [tempPriceChanges, setTempPriceChanges] = useState({});
  const [showItemsWithQuantity, setShowItemsWithQuantity] = useState(false);
  const [total, setTotal] = useState(0);
  const [showEditPopup, setShowEditPopup] = useState(false);

  const [editingItem, setEditingItem] = useState(null);
  const [editFormData, setEditFormData] = useState({
    itemName: "",
    unitType: "",
    itemImageUrl: "",
    itemPriceDollars: "",
    itemPriceCents: "",
    quantity: 0,
  });




  
  const [customerName, setCustomerName] = useState("");
  const [isTableView, setIsTableView] = useState(true   );
  const [originalPrices, setOriginalPrices] = useState({});
  const [isPriceZero, setIsPriceZero] = useState(true);
  const [isSiteOn, setIsSiteOn] = useState(true); // State to track if the site is on

  const quantityInputRef = useRef(null);
  const priceInputRef = useRef(null);
  
  const togglePrices = () => {
    if (isPriceZero) {
      // Restore original prices
      setItems((prevItems) =>
        prevItems.map((item) => ({
          ...item,
          price: originalPrices[item.id] || item.price,
        }))
      );
    } else {
      // Set all prices to zero
      setItems((prevItems) =>
        prevItems.map((item) => ({
          ...item,
          price: "0.00",
        }))
      );
    }
    setIsPriceZero(!isPriceZero);
  };
  
  
  function generatePDF() {
    pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts.vfs;
    pdfMake.vfs["SecularOne-Regular.ttf"] = SecularOneRegularBase64;
    const reversedCustomerName = reverseCustomerName(customerName);
    const productTracker = {};


    // Filter items with quantity greater than 0 and calculate total price for each item
    const filteredItems = items
      .filter((item) => item.quantity > 0)
      .map((item) => {
        const totalPrice = parseFloat(item.price) * item.quantity;
        const reversedItemName = reverseHebrewText(item.itemName);
        // Adjust the order here: Place price before quantity
        return {
          totalPrice: totalPrice.toFixed(2), // Total price without currency symbol
          pricePerUnit: `${item.price}₪`, // Price per unit
          quantity: item.quantity.toString(), // Quantity
          itemName: reversedItemName, // Item name
          isNegative: totalPrice < 0, // Flag for negative total price

        };
      })
      .filter((item) => {
        if (productTracker[item.itemName]) {
          return false; // Skip duplicates
        }
        productTracker[item.itemName] = true;
        return true;
      });
  
      const positiveItems = filteredItems.filter((item) => !item.isNegative);
      const negativeItems = filteredItems.filter((item) => item.isNegative);
    
    const sortedItems = [...positiveItems, ...negativeItems];

    const now = new Date();
    const formattedDate = now.toLocaleDateString("he-IL");
    const formattedTime = now.toLocaleTimeString("he-IL");

    // Calculate the total of all items combined

  // Calculate totals
  const totalPositive = positiveItems.reduce((sum, item) => sum + parseFloat(item.totalPrice), 0);
  const totalNegative = negativeItems.reduce((sum, item) => sum + parseFloat(item.totalPrice), 0);
  const difference = totalPositive - Math.abs(totalNegative);
  const totalWithTax = difference * 1.18;
  const taxAmount = totalWithTax - difference;


  // Create the table body
  const tableBody = [
    ["סה\"כ", "מחיר", "כמות", "מוצר"].map((text) => ({
      text,
      alignment: "right",
    })),
    ...positiveItems.map((item) => [
      { text: `${item.totalPrice}₪`, alignment: "right" },
      { text: item.pricePerUnit, alignment: "right" },
      { text: item.quantity, alignment: "right" },
      { text: item.itemName, alignment: "right", fontSize: 16 },
    ]),
    [{ text: `${totalPositive.toFixed(2)}₪`, alignment: "right" }, "", "", " חיובים סה''כ "],
     // Conditionally render the negative items and זיכויים סה"כ row
  ...(totalNegative !== 0 ? [
    ...negativeItems.map((item) => [
      { text: `${item.totalPrice}₪`, alignment: "right", fillColor: "yellow" },
      { text: item.pricePerUnit, alignment: "right", fillColor: "yellow" },
      { text: item.quantity, alignment: "right", fillColor: "yellow" },
      { text: item.itemName, alignment: "right", fontSize: 16, fillColor: "yellow" },
    ]),
    [{ text: `${Math.abs(totalNegative).toFixed(2)}₪`, alignment: "right" }, "", "", " זיכויים סה''כ "]
  ] : []), // If totalNegative is 0, skip adding this section
  ];

  const docDefinition = {
    content: [
      {
        text: `${formattedDate}     ${formattedTime}`,
        style: "date",
      },
      {
        text: "מחיר הצעת  ",
        style: "header",
      },
      customerName && {
        text: `${reversedCustomerName} לקוח:`,
        style: "subheader",
        alignment: "right",
      },
      {
        table: {
          body: tableBody,
          widths: ["*", "*", "*", "*"], // Adjust column widths as needed
        },
        layout: "lightHorizontalLines", // Optional: Adds layout to the table
        rtl: true, // Right-to-Left text
      },
      // Signature on the right, totals stacked on the left
      {
        table: {
          body: [
            [
              {
                // This cell contains the two totals stacked on top of each other
                stack: [
                  // Conditionally render זיכויים פחות חיובים סה"כ only if totalNegative is not zero
                  totalNegative !== 0 && { 
                    text: `${difference.toFixed(2)}₪ : מע''מ לפני סה"כ `, 
                    alignment: "right" ,
                    margin: [45, 20, 45, 0], // Add vertical margin here

                  },

                  { 
                    text: `${taxAmount.toFixed(2)}₪ : מע"מ סה"כ `,  // Add the specific tax amount here
                    alignment: "right",
                    margin: [45, 10, 45, 0],  // Adjust margins for spacing
                  },
                  { 
                    text: `${totalWithTax.toFixed(2)}₪ : מע"מ כולל סה"כ `, 
                    alignment: "right" ,
                    margin: [45, 10, 45, 0], // Add vertical margin here

                    
                  },
                ].filter(Boolean), // Remove falsy values if the negative total is 0
                border: [false, false, false, false], // No borders
              },
              {
                text: "חתימה:_______________________", // Signature
                alignment: "left", // Align signature to the left
                border: [false, false, false, false], // No borders
                margin: [10, 10, 0, 0], // Add left and top margins to separate it from the totals

              },
            ],
          ],
          widths: ["*", "auto"], // Adjust the width of the columns as needed
        },
        layout: "noBorders", // No borders for the entire signature and totals section
      },
      {
        text: " ",
        style: "subheader",
        alignment: "right",
      },
    ],
    defaultStyle: {
      font: "DejaVuSans",
    },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: "right", // Right align the header
      },
      subheader: {
        fontSize: 15,
        bold: true,
        margin: [0, 5, 0, 5], // Adjust as needed
      },
      paragraph: {
        fontSize: 15,
        margin: [0, 5, 0, 5], // Adjust as needed
      },
      date: {
        fontSize: 9,
        margin: [0, 5, 0, 5], // Adjust as needed
      },
      total: {
        fontSize: 15,
        bold: true,
        margin: [0, 50, 0, 5], // Adjust as needed
      },
    },
  };
  
  try {
    pdfMake.createPdf(docDefinition).download("טופס הזמנה.pdf");
  } catch (error) {
    console.error("Error generating PDF: ", error);
    alert("Failed to generate PDF. Please try again later.");
  }
  
  }

  const handleEditClick2 = (item) => {
    setEditingItem(item);
    setEditFormData({
      itemName: item.itemName,
      unitType: item.unitType,
      itemImageUrl: item.itemImageUrl,
      itemPriceDollars: item.price.split(".")[0],
      itemPriceCents: item.price.split(".")[1] || "00",
      quantity: item.quantity,
    });

    // Close other popups
    setShowPopup(false);
    setShowGeneralPopup(false);
    setShowEditPopup(true); // Open edit popup
  };

  const handleEditSubmit2 = async () => {
    const updatedItem = {
      ...editingItem,
      ...editFormData,
      price: `${editFormData.itemPriceDollars}.${editFormData.itemPriceCents}`,
    };

    try {
      const itemRef = doc(
        db,
        "wwwebs",
        "MeshekDaniel",
        "Products",
        editingItem.id
      );
      await setDoc(itemRef, updatedItem);
      alert("Product updated successfully!");
      // Update the local state to reflect the change
      setItems((prevItems) =>
        prevItems.map((item) =>
          item.id === editingItem.id ? updatedItem : item
        )
      );
      setShowEditPopup(false); // Close the edit pop-up
    } catch (error) {
      console.error("Error updating product: ", error);
      alert("Failed to update product. Please try again later.");
    }
    setEditingItem(null); // Reset editing item state
  };

  const handleCategorySelection = (category) => {
    setSelectedCategories((prevCategories) => {
      if (prevCategories.includes(category)) {
        return prevCategories.filter((cat) => cat !== category);
      } else {
        return [...prevCategories, category];
      }
    });
  };

  const toggleShowItemsWithQuantity = () => {
    setShowItemsWithQuantity(!showItemsWithQuantity);
  };

  const isCategorySelected = (category) => {
    return selectedCategories.includes(category);
  };
  const [itemName, setItemName] = useState("");
  const [unitType, setUnitType] = useState("Count");
  const [itemImageUrl, setItemImageUrl] = useState("");
  const [itemPriceDollars, setItemPriceDollars] = useState("");
  const [itemPriceCents, setItemPriceCents] = useState("");

  const togglePopup = () => {
    setShowPopup(!showPopup);
    setShowEditPopup(false);
    setShowGeneralPopup(false);
  };

  const toggleGeneralPopup = () => {
    setShowGeneralPopup(!showGeneralPopup);
    setShowPopup(false);
    setShowEditPopup(false);
  };

  const toggleEditPopup = () => {
    setShowEditPopup(!showEditPopup);
  };

  const sortedItems = [...items].sort((a, b) => {
    if (categoryOrder[a.unitType] === categoryOrder[b.unitType]) {
      return a.itemName.localeCompare(b.itemName); // Sort by name if same category
    }
    return categoryOrder[a.unitType] - categoryOrder[b.unitType]; // Sort by category
  });

  const resetForm = () => {
    setEditingItem(null);
    setEditFormData({
      itemName: "",
      unitType: "",
      itemImageUrl: "",
      itemPriceDollars: "",
      itemPriceCents: "",
      quantity: 0,
    });
    setItemName(""); // Add this line
    setUnitType("Count");
    setItemImageUrl("");
    setItemPriceDollars("");
    setItemPriceCents("");
  };

  const filteredAndSortedItems = items
    .filter((item) => {
      const itemNameLower = item.itemName.toLowerCase();
      const unitTypeLower = item.unitType.toLowerCase();
      const filterQueryLower = filterQuery.toLowerCase();
      const categoryFilter =
        selectedCategories.length === 0 ||
        selectedCategories.includes(item.unitType);

      return (
        (itemNameLower.includes(filterQueryLower) ||
          unitTypeLower.includes(filterQueryLower)) &&
        categoryFilter &&
        (!showItemsWithQuantity || item.quantity > 0)
      );
    })
    .sort((a, b) => {
      if (categoryOrder[a.unitType] === categoryOrder[b.unitType]) {
        return a.itemName.localeCompare(b.itemName);
      }
      return categoryOrder[a.unitType] - categoryOrder[b.unitType];
    });

  const handleGeneralSubmit = async () => {
    const newItem = {
      itemName,
      unitType,
      itemImageUrl: "",
      price: `${itemPriceDollars}.${itemPriceCents || "00"}`,
      quantity: generalQuantity,
    };

    setItems((prevItems) => [...prevItems, { id: itemName, ...newItem }]);
    setItemQuantities((prevQuantities) => [...prevQuantities, generalQuantity]);

    setItemName("");
    setUnitType("Count");
    setItemPriceDollars("");
    setItemPriceCents("");
    setGeneralQuantity(0);

    toggleGeneralPopup();
  };

  const db = getFirestore();

  const fetchItems = async () => {
    const itemsCollection = collection(db, "wwwebs", "MeshekDaniel", "Products");
    const itemsSnapshot = await getDocs(itemsCollection);
    const itemsList = itemsSnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        id: doc.id,
        quantity: 0, // Initialize quantity for each item
        originalPrice: data.price,
        price: "0.00", // Set initial price to zero
      };
    });
  
    setItems(itemsList);
    setOriginalPrices(
      itemsList.reduce((acc, item) => {
        acc[item.id] = item.originalPrice;
        return acc;
      }, {})
    );
  };
  

  const fetchSubscriptionStatus = async () => {
    const subscriptionDocRef = doc(
      db,
      "wwwebs",
      "MeshekDaniel",
      "subscription",
      "subscription"
    );
    const subscriptionDoc = await getDoc(subscriptionDocRef);
    if (subscriptionDoc.exists()) {
      const data = subscriptionDoc.data();
      setIsSiteOn(data.on);
    } else {
      console.error("Subscription document not found");
      setIsSiteOn(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionStatus();
    fetchItems();
  }, []);

  

  useEffect(() => {
    setTotal(calculateTotal());
  }, [items, tempPriceChanges, itemQuantities]);

  const handleManualQuantityChange = (itemId, value) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId
          ? { ...item, quantity: Math.max(0, parseInt(value, 10) || 0) }
          : item
      )
    );
  };

  const handleEditClick = (item) => {
    setEditingItem(item);
    setEditFormData({
      itemName: item.itemName,
      unitType: item.unitType,
      itemImageUrl: item.itemImageUrl,
      itemPriceDollars: item.price.split(".")[0],
      itemPriceCents: item.price.split(".")[1] || "00",
      quantity: item.quantity,
    });

    // Update the unitType state to reflect the editing item's unitType
    setUnitType(item.unitType);

    setItemName(item.itemName);
    setShowPopup(true);
  };

  const handleEditSubmit = async () => {
    const updatedItem = {
      ...editingItem,
      itemName: editFormData.itemName,
      unitType: editFormData.unitType,
      itemImageUrl: editFormData.itemImageUrl,
      price: `${editFormData.itemPriceDollars}.${editFormData.itemPriceCents}`,
      quantity: editFormData.quantity,
    };

    try {
      const itemRef = doc(
        db,
        "wwwebs",
        "MeshekDaniel",
        "Products",
        editingItem.id
      );
      await setDoc(itemRef, updatedItem);
      alert("Product updated successfully!");

      setItems((prevItems) =>
        prevItems.map((item) =>
          item.id === editingItem.id ? updatedItem : item
        )
      );
      setShowPopup(false);
    } catch (error) {
      console.error("Error updating product: ", error);
      alert("Failed to update product. Please try again later.");
    }

    setEditingItem(null);
    resetForm();
  };

  const handleAddItem = async () => {
    if (!itemName) {
      alert("Please enter a product name.");
      return;
    }

    const productData = {
      itemName,
      unitType,
      itemImageUrl, // Use the itemImageUrl state here
      price: `${itemPriceDollars}.${itemPriceCents || "00"}`,
      quantity: generalQuantity,
    };

    try {
      const productRef = doc(
        db,
        "wwwebs",
        "MeshekDaniel",
        "Products",
        itemName
      );
      await setDoc(productRef, productData);
      alert("Product added successfully!");

      setItems((prevItems) => [...prevItems, { id: itemName, ...productData }]);
      resetForm();
    } catch (error) {
      console.error("Error adding product: ", error);
      alert("Failed to add product. Please try again later.");
    }
  };

  const handleUnitTypeChange = (value) => {
    setUnitType(value);
  };

  const handleNumberInputChange = (value, setterFunction) => {
    if (/^\d*$/.test(value)) {
      setterFunction(value);
    }
  };

  const handleQuantityChange = (itemId, delta) => {
    setItems((currentItems) =>
      currentItems.map((item) => {
        if (item.id === itemId) {
          return { ...item, quantity: Math.max(0, item.quantity + delta) };
        }
        return item;
      })
    );
  };

  const calculateTotal = () => {
    return items.reduce((acc, item) => {
      const price =
        tempPriceChanges[item.id] !== undefined
          ? parseFloat(tempPriceChanges[item.id]) || 0
          : parseFloat(item.price) || 0;
      return acc + price * (item.quantity || 0);
    }, 0);
  };

  const toggleView = () => {
    setIsTableView(!isTableView);
  };
  const handleFocus = (event) => {
    event.target.select();
  };
  if (!isSiteOn) {
    return <div className="debtmessage">קיים חוב, אנא שלם כדי להמשיך להשתמש באתר</div>;
  }

  return (
    <div className="home-container">
      <div className="top-buttons">
        <button
          onClick={toggleShowItemsWithQuantity}
          style={{ backgroundColor: showItemsWithQuantity ? "green" : "" }}
        >
          {showItemsWithQuantity ? "תצוגת הזמנה" : "הזמנה נוכחית"}
        </button>
        <button onClick={togglePopup}>מוצר חדש</button>
        <button onClick={toggleGeneralPopup}>מוצר כללי</button>
        <button
          onClick={() => handleCategorySelection("Flowers")}
          style={{
            backgroundColor: isCategorySelected("Flowers")
              ? "rgba(255, 0, 0, 0.5)"
              : "rgb(255, 101, 181)",
          }}
        >
          פרחים
        </button>
        <button
          onClick={() => handleCategorySelection("Vegetables")}
          style={{
            backgroundColor: isCategorySelected("Vegetables")
              ? "rgba(0, 128, 0, 0.5)"
              : "rgb(0, 128, 0)",
          }}
        >
          ירקות
        </button>
        <button
          onClick={() => handleCategorySelection("Others")}
          style={{
            backgroundColor: isCategorySelected("Others")
              ? "rgba(0, 0, 255, 0.5)"
              : "rgb(47, 224, 255)",
          }}
        >
          שונות
        </button>
      </div>
      <div className="customer-name-input">
        <button onClick={toggleView}>
          {isTableView ? "תמונות" : "טבלה"}
        </button>

        <button onClick={togglePrices}>
    {isPriceZero ? "מחיר" : "איפוס מחירים"}
  </button>
        <h3> ברוכים הבאים למשק דניאל</h3>
        <input
            type="text"
            placeholder="שם מוצר/ קטגוריה / צבע"
            value={filterQuery}
            onChange={(e) => setFilterQuery(e.target.value)}
          />
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>{editingItem ? "עריכת מוצר" : "הוספת מוצר חדש"}</h3>
            <div>
              <div>
                <label htmlFor="flowers">Flowers</label>
                <input
                  type="radio"
                  id="flowers"
                  name="unitType"
                  value="Flowers"
                  checked={unitType === "Flowers"}
                  onChange={() => setUnitType("Flowers")}
                />
                <label htmlFor="vegetables">vegetables</label>
                <input
                  type="radio"
                  id="vegetables"
                  name="unitType"
                  value="Vegetables"
                  checked={unitType === "Vegetables"}
                  onChange={() => setUnitType("Vegetables")}
                />
                <label htmlFor="others">Others</label>
                <input
                  type="radio"
                  id="others"
                  name="unitType"
                  value="Others"
                  checked={unitType === "Others"}
                  onChange={() => setUnitType("Others")}
                />
              </div>
            </div>
            <input
              type="text"
              placeholder="שם מוצר"
              value={itemName} // Change this line
              onChange={(e) => setItemName(e.target.value)} // And this line
            />
            <label>:מוצר </label>
            <div className="popup-item-image">
              <input
                type="text"
                placeholder="כתובת תמונה - קישור "
                value={itemImageUrl}
                onChange={(e) => setItemImageUrl(e.target.value)}
              />
              <label>:תמונה</label>
            </div>
            <div>
              <input
                type="text"
                placeholder="מחיר בשקלים"
                value={itemPriceDollars}
                onChange={(e) => setItemPriceDollars(e.target.value)}
              />
              <input
                type="text"
                placeholder="מחיר באגורות"
                value={itemPriceCents}
                onChange={(e) => setItemPriceCents(e.target.value)}
              />
              <label>Price: </label>
            </div>
            <button onClick={editingItem ? handleEditSubmit : handleAddItem}>
              {editingItem ? "עדכון" : "הוספה"}
            </button>
            <button onClick={togglePopup}>סגירה</button>
          </div>
        </div>
      )}

      {showEditPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>עריכת מוצר </h3>
            <div>
              <div>
                <label htmlFor="flowers">Flowers</label>
                <input
                  type="radio"
                  id="flowers"
                  name="unitType"
                  value="Flowers"
                  checked={editFormData.unitType === "Flowers"}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      unitType: e.target.value,
                    })
                  }
                />
                <label htmlFor="vegetables">vegetables</label>
                <input
                  type="radio"
                  id="vegetables"
                  name="unitType"
                  value="Vegetables"
                  checked={editFormData.unitType === "Vegetables"}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      unitType: e.target.value,
                    })
                  }
                />
                <label htmlFor="others">Others</label>
                <input
                  type="radio"
                  id="others"
                  name="unitType"
                  value="Others"
                  checked={editFormData.unitType === "Others"}
                  onChange={(e) =>
                    setEditFormData({
                      ...editFormData,
                      unitType: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <input
              type="text"
              placeholder="שם מוצר"
              value={editFormData.itemName}
              onChange={(e) =>
                setEditFormData({ ...editFormData, itemName: e.target.value })
              }
            />
            <label>:מוצר </label>
            <div className="popup-item-image">
              <input
                type="text"
                placeholder="כתובת תמונה - קישור"
                value={editFormData.itemImageUrl}
                onChange={(e) =>
                  setEditFormData({
                    ...editFormData,
                    itemImageUrl: e.target.value,
                  })
                }
              />
              <label>:תמונה</label>
            </div>
            <div>
              <input
                type="text"
                placeholder="מחיר בשקלים"
                value={editFormData.itemPriceDollars}
                onFocus={handleFocus} // Add this line

                onChange={(e) =>
                  setEditFormData({
                    ...editFormData,
                    itemPriceDollars: e.target.value,
                  })
                }
              />
              <input
                type="text"
                placeholder="מחיר באגורות"
                onFocus={handleFocus} // Add this line

                value={editFormData.itemPriceCents}
                onChange={(e) =>
                  setEditFormData({
                    ...editFormData,
                    itemPriceCents: e.target.value,
                  })
                }
              />
              <label>מחיר: </label>
            </div>
            <button onClick={handleEditSubmit2}>עדכון</button>
            <button onClick={toggleEditPopup}>סגירה</button>
          </div>
        </div>
      )}

      {showGeneralPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>הוספת מוצר כללי</h3>
            <div>
              <div>
                <label htmlFor="flowers">Flowers</label>
                <input
                  type="radio"
                  id="flowers"
                  name="unitType"
                  value="Flowers"
                  checked={unitType === "Flowers"}
                  onChange={() => handleUnitTypeChange("Flowers")}
                />
                <label htmlFor="vegetables">vegetables</label>
                <input
                  type="radio"
                  id="vegetables"
                  name="unitType"
                  value="Vegetables"
                  checked={unitType === "Vegetables"}
                  onChange={() => handleUnitTypeChange("Vegetables")}
                />
                <label htmlFor="others">Others</label>
                <input
                  type="radio"
                  id="others"
                  name="unitType"
                  value="Others"
                  checked={unitType === "Others"}
                  onChange={() => handleUnitTypeChange("Others")}
                />
              </div>
            </div>
            <input
              type="text"
              placeholder="שם מוצר"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
            />
            <label>:מוצר </label>
            <div className="popup-item-image">
              <input
                type="number"
                placeholder="כמות"
                value={generalQuantity}
                onChange={(e) => setGeneralQuantity(Number(e.target.value))}
              />
              <label>כמות: </label>
            </div>
            <div>
              <input
                type="number" // Change this line
                placeholder="שקלים"
                onFocus={handleFocus} // Add this line
                value={itemPriceDollars}
                onChange={(e) =>
                  handleNumberInputChange(e.target.value, setItemPriceDollars)
                }
              />
              <a> </a>
              <label>:מחיר </label>
              <input
                type="number" // Change this line
                placeholder="אגורות"
                onFocus={handleFocus} // Add this line

                value={itemPriceCents}
                onChange={(e) =>
                  handleNumberInputChange(e.target.value, setItemPriceCents)
                }
              />
              <label>Price: </label>
            </div>
            <button onClick={handleGeneralSubmit}>Submit</button>
            <button onClick={toggleGeneralPopup}>Close</button>
          </div>
        </div>
      )}
      {/* Items display */}
      <div>
      <div className={isTableView ? "table-container" : "items-container"}>
        {isTableView ? (
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
    
              <th>עריכה</th>

                <th>מחיר</th>
                <th>הפחת</th>

                <th>כמות</th>

                <th>הוסף</th>

                <th>שם מוצר</th>
              </tr>
            </thead>
            <tbody>
              {filteredAndSortedItems.map((item) => (
                <tr key={item.id}>
                                    <td>
                    <button
                      className="small-edit-btn"
                      onClick={() => handleEditClick2(item)}
                    >
                      עריכה
                    </button>
                  </td>
                  <td>
  <input
    type="number"
    onFocus={handleFocus}
    value={
      tempPriceChanges[item.id] !== undefined
        ? tempPriceChanges[item.id]
        : parseFloat(item.price).toFixed(2)
    }
    onChange={(e) =>
      setTempPriceChanges({
        ...tempPriceChanges,
        [item.id]: e.target.value,
      })
    }
    onBlur={() => {
      // Update the item's price temporarily
      setItems((prevItems) =>
        prevItems.map((it) =>
          it.id === item.id
            ? { ...it, price: tempPriceChanges[item.id] }
            : it
        )
      );
      setTempPriceChanges({
        ...tempPriceChanges,
        [item.id]: undefined,
      });
    }}
    style={{ width: "55px" }}
  />
</td>

                  <td>
                  <button
                      className="small-btn"
                      onClick={() => handleQuantityChange(item.id, -1)}
                    >
                      -
                    </button>
                  </td>


                  <td>
                    <input
                    
                    type="number"
                    onFocus={handleFocus}                      value={item.quantity}
                      onChange={(e) =>
                        handleManualQuantityChange(item.id, e.target.value)
                      }
                      min="0"
                      style={{ width: "30px" }} 
                    />
                  </td>



                  <td>
                  <button
                      className="small-btn"
                      onClick={() => handleQuantityChange(item.id, 1)}
                    >
                      +
                    </button> 
                  </td>

                  <td>{item.itemName}</td>

                </tr>
              ))}
            </tbody>
          </table>
        ) : 
        

        (
          
          filteredAndSortedItems.map((item) => (
            <div key={item.id} className={`item ${item.unitType.toLowerCase()}`}>
              <div className="item-info">
                <h3>{item.itemName}</h3>
                {item.itemImageUrl && (
                  <img
                    src={item.itemImageUrl}
                    alt={item.itemName}
                    className="product-image"
                  />
                )}
                {tempPriceChanges[item.id] !== undefined ? (
                  <div>
                    <input
                      type="number"
                      value={tempPriceChanges[item.id]}
                      onChange={(e) =>
                        setTempPriceChanges({
                          ...tempPriceChanges,
                          [item.id]: e.target.value,
                        })
                      }
                    />
                    <button
                      onClick={() => {
                        // Update the item's price temporarily
                        setItems((prevItems) =>
                          prevItems.map((it) =>
                            it.id === item.id
                              ? { ...it, price: tempPriceChanges[item.id] }
                              : it
                          )
                        );
                        setTempPriceChanges({
                          ...tempPriceChanges,
                          [item.id]: undefined,
                        });
                      }}
                    >
                      Submit
                    </button>
                  </div>
                ) : (
                  <h3
                    onClick={() =>
                      setTempPriceChanges({
                        ...tempPriceChanges,
                        [item.id]: item.price,
                      })
                    }
                  >
                    {item.price}₪
                  </h3>
                )}
              </div>
              <div className="item-quantity">
                <button onClick={() => handleQuantityChange(item.id, -1)}>
                  -
                </button>
                <input
                  type="number"
                  value={item.quantity}
                  onChange={(e) =>
                    handleManualQuantityChange(item.id, e.target.value)
                  }
                  min="0"
                />
                <button onClick={() => handleQuantityChange(item.id, 1)}>
                  +
                </button>
              </div>
              <div className="item-total">
                <label>
                  סה"כ:{" "}
                  {(
                    (tempPriceChanges[item.id] !== undefined
                      ? tempPriceChanges[item.id]
                      : item.price) * item.quantity
                  ).toFixed(2)}
                  ₪
                </label>
              </div>
              <button
                className="small-edit-btn"
                onClick={() => handleEditClick2(item)}
              >
                עריכה
              </button>
            </div>
          ))
        )
        }
      </div>
      <div className="bottom-bar">
        <button onClick={generatePDF}>PDF</button>
        <a>סה"כ: {isNaN(total) ? 0 : total.toFixed(2)}₪</a>
        <div className="bottominputs">

                  <input
          type="text"
          placeholder="שם הלקוח"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
        />
                  <input
            type="text"
            placeholder="שם מוצר/ קטגוריה / צבע"
            value={filterQuery}
            onChange={(e) => setFilterQuery(e.target.value)}
          />
        </div>
      </div>
      </div>
    </div>
  );
}

export default Home;
